import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import { signIn } from "../../models/actions/authActions";
import "../../assets/css/auth.css";
import "../../assets/css/common.css";
import { validEmailRegex, validPwdRegex } from "../../utils/AuthValidation";
import * as Yup from "yup";

import {
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function SignIn() {
  const dispatch = useDispatch();
  const history = useHistory();

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!validEmailRegex.test(values.email)) {
      errors.email = 'Please enter a valid email address.';
    }

    if (!values.password) {
      errors.password = 'Required';
    } else if (!validPwdRegex.test(values.password)) {
      errors.password = 'It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.';
    }

    return errors;
  };

  return (
    <Wrapper>
      <Helmet title="Sign In" />
      <div>
        <div align="center">
          <img src="/static/img/logo/ecosense-logo.jpg"></img>
        </div>
        <div className='s20'></div>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Sign in to subscription service
        </Typography>
        <div class='s40'></div>
        <Typography component="h2" variant="body1" align="left">
          Sign in to your account to continue
        </Typography>
      </div>

      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: false,
        }}
        validate={validate}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const trimmedEmail = values.email.trim().toLowerCase();
          try {
            await dispatch(
              signIn({ email: trimmedEmail, password: values.password })
            );
            history.push("/private");
          } catch (error) {
            let message = error.message || "Something went wrong";
            if (error.code === 'UserNotConfirmedException') {
              message = 'Account not verified yet';
              history.push({
                pathname: '/auth/passcode-required',
                state: {
                  from: '/auth/sign-in',
                  email: trimmedEmail
                }
              });
            } else if (error.code === 'PasswordResetRequiredException') {
              message = 'Existing user found. Please reset your password';
            } else if (error.code === 'NotAuthorizedException') {
              message = "User and password don't match or user doesn't exist";
            } else if (error.code === 'UserNotFoundException') {
              message = "Email and password don't match or user doesn't exist";
            }
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
          <form noValidate onSubmit={handleSubmit}>
            {/*<Alert mt={3} mb={1} severity="info">*/}
            {/*  Use <strong>demo@bootlab.io</strong> and{" "}*/}
            {/*  <strong>unsafepassword</strong> to sign in*/}
            {/*</Alert>*/}
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              // variant="outlined"
              my={3}
            />
            <TextField
              type="password"
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              // variant="outlined"
              my={0}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <p></p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              classes={{ root: 'button', label: 'button-label' }}
            >
              SIGN IN
            </Button>
            <Button
              component={Link}
              to="/auth/forgot-password"
              fullWidth
              color="primary"
              classes={{ label: 'link-text' }}
            >
              Forgot password
            </Button>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default SignIn;
