export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
);
export const validPwdRegex = RegExp(
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
);

// false if the name has nothing or has numbers or special characters
export const validNameRegex = RegExp(
  "^[a-zA-Z\\\\s]+"
);

export const validateEmail = (email) => {
  if (!validEmailRegex.test(email)) {
    return 'Please enter a valid email address.';
  }
  return null;
};

export const validatePassword = (pwd) => {
  if ((pwd.length < 8)||!pwd.match(validPwdRegex)) {
    return 'It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.';
  }
  return null;
};

export const validateWiFiPassword = (pwd) => {
  return null;
};

export const validateCode = (code) => {
  if (code.length !== 6 || isNaN(code)) {
    return 'Passcode must be 6 digits';
  }
  return null;
};
