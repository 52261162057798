import axios from "../../utils/axios";
import { Auth } from 'aws-amplify';

import AWS from 'aws-sdk';
// AWS.config.region = 'us-west-2';
// const apig = new AWS.APIGateway({apiVersion: '2015/07/09'});

// https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-api-gateway/index.html
// import { APIGatewayClient, CreateApiKeyCommand } from "@aws-sdk/client-api-gateway";
// const client = new APIGatewayClient({ region: "us-west-2" });

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    Auth.signIn(credentials.email, credentials.password)
      .then((response) => {
        console.log('signIn response:' + JSON.stringify(response));
        if (response.username) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function signOut() {
  return new Promise((resolve, reject) => {
    Auth.signOut()
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    Auth.signUp({
      username: credentials.email,
      password: credentials.password,
      attributes: {
        name: credentials.name,
      }
    })
      .then((response) => {
        console.log('signUp response:' + JSON.stringify(response));
        if (response.user.username) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function resendSignUp(email) {
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(email)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function verifyCode(code) {
  return new Promise((resolve, reject) => {
    Auth.confirmSignUp(code.email, code.passcode, {
      forceAliasCreation: true,
    })
      .then((response) => {
        console.log('verifyCode response:' + JSON.stringify(response));
        if (response === "SUCCESS") {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function forgotPassword(email) {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(email)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function resetPassword(email, passcode, password) {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(email, passcode, password)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export async function registerSubscription(info) {
  console.log('info:' + JSON.stringify(info));
  // Dev
  // const apiEndPointForDev = "https://dev-api.cloud.ecosense.io/api/v1/mitigator";
  const apiEndPointForDev = "https://dev-api.cloud.ecosense.io/api/v1/mitigator";
  const headersForDev = {
    "Content-Type": "application/json",
    // "x-api-key": "x1KBNCB5N29wnvqjaWtWh7Pa4GhI4Fne7cfSMF7z",
    "x-api-key": "l7MdnbzHA88pr0hYC4vb983MgfeNi5ub7V3jhCXp",
  };

  const apiEndPointForDev2 = "https://dev-api.cloud.ecosense.io/api/v1/device?email=choi3incommon@gmail.com";
  const headersForDev2 = {
    "Content-Type": "application/json",
    // "x-api-key": "x1KBNCB5N29wnvqjaWtWh7Pa4GhI4Fne7cfSMF7z",
    // "x-api-key": "l7MdnbzHA88pr0hYC4vb983MgfeNi5ub7V3jhCXp",
    // TODO: need to update authorization token
    "Authorization": "eyJraWQiOiJiT0xQNzdtRmxxdnJFNmdsMHRBRHJjMVVPbFdZMjJuRERhTWYyRXhSZ1RnPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIzNjZkYjUxNy02ODBjLTQ1MTYtYTc1My0xNGMzZWU0NmFhY2UiLCJhdWQiOiIzOHRicHRiZ21rb3FzYjZjaGJxMHFkdXRoYiIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJldmVudF9pZCI6IjBlNWE0Y2EyLWFmYmItNGZmOC1hOTcyLTE5YmMxZmRhYjczYyIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjIyNjkwMjA3LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9jeXN1Zm03Y3kiLCJuYW1lIjoiLSIsImNvZ25pdG86dXNlcm5hbWUiOiIzNjZkYjUxNy02ODBjLTQ1MTYtYTc1My0xNGMzZWU0NmFhY2UiLCJleHAiOjE2MjI2OTM4MDcsImlhdCI6MTYyMjY5MDIwNywiZW1haWwiOiJjaG9paW5jb21tb25AZ21haWwuY29tIn0.ijZzOxBr1XMCZjc51THwJ-3a8Ayf2koc07JRSsThdp1Hd2Mndd4gfZzM3B4H4djpAq1bnAljn6wSkRAr13IhiAcrDR4ZRcamqbo2_MFWkMsc7jyZHBwUHzISfZlsrOEwlMi2sNk2yrmE9lkKieYzq1HL5z30jfn6eiU4RZ9UOT5rtxO9sOiotR1t4NyO5359JN8tRF0fHsHwOOhVrBPH97UGS4KdS4szwURVEaTjUs9ktVgx_6POnyb2io83xYd1FSzZzlgGGmASPK_Qnn4Wh_s4ihpkMsFmOBrGkyJnsLpa09-UIpegSDTQlF_eUhFQkr2l8IQZ33VSxwkkI82gBw",
  };


  // Prod
  const apiEndPointForProd = "https://api.cloud.ecosense.io/api/v1/mitigator";
  const headersForProd = {
    "Content-Type": "application/json",
    "x-api-key": "hx5lHNle017aixo58SKyaaXc8LFBgTwm1uEGQH2Y"
  };

  const apiEndPoint = apiEndPointForDev2;
  const headers = headersForDev2;

  const data = {
    "email": info.email,
    "name": info.name,
    "companyName": info.companyName,
    "phoneNumber": info.phoneNumber,
    "address": info.address,
    "city": info.city,
    "state": info.state,
    "zipCode": info.zipCode
  };

  const options = {
    method: 'POST',
    headers: headers,
    mode: 'no-cors',
    credentials: 'include',
    body : JSON.stringify(data)
  };

  return new Promise((resolve, reject) => {
    console.log('OPTIONS===' + JSON.stringify(options));
      fetch(apiEndPoint, options)
      .then((response) => {
        alert('Success!' + JSON.stringify(response));
        console.log('response:' + JSON.stringify(response));
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        alert('Fail!' + JSON.stringify(error));
        console.log('response:' + JSON.stringify(error));
        reject(error);
      });

    // axios
    //   .post("https://api.cloud.ecosense.io/api/v1/mitigator", options)
    //   .then((response) => {
    //     alert('Success!' + JSON.stringify(response));
    //     console.log('response:' + JSON.stringify(response));
    //     if (response.status === 200) {
    //       resolve(response.data);
    //     }
    //     reject(response.data);
    //   })
    //   .catch((error) => {
    //     alert('Fail!' + JSON.stringify(error));
    //     console.log('response:' + JSON.stringify(error));
    //     reject(error);
    //   });
  });
};

/*
  Direct call using AWS SDK
  git: https://www.npmjs.com/package/aws-api-gateway-client
  aws: https://docs.aws.amazon.com/apigateway/latest/developerguide/how-to-generate-sdk-javascript.html
  check CORS: curl -v -X OPTIONS https://api.cloud.ecosense.io/api/v1/mitigator
  CORS: https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS
 */
export async function registerSubscription2(info) {
  console.log('info:' + JSON.stringify(info));
  const apigClientFactory = require('aws-api-gateway-client').default;
  var apigClient = apigClientFactory.newClient({
    invokeUrl:'https://api.cloud.ecosense.io/api/v1',
    apiKey: 'hx5lHNle017aixo58SKyaaXc8LFBgTwm1uEGQH2Y',
    region: 'us-west-2'
  });

  var pathParams = {};
  var pathTemplate = '/mitigator'
  var method = 'POST';
  var additionalParams = {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "hx5lHNle017aixo58SKyaaXc8LFBgTwm1uEGQH2Y",
    },
    queryParams: {}
  };

  const body = {
    "email": info.email,
    "name": info.name,
    "companyName": info.companyName,
    "phoneNumber": info.phoneNumber,
    "address": info.address,
    "city": info.city,
    "state": info.state,
    "zipCode": info.zipCode
  };

  return new Promise((resolve, reject) => {
    apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then(function(response){
        alert('success!');
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      }).catch( function(error){
      alert('error!' + error);
      reject(error);
    });
  });
};


