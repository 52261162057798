import React from "react";
import { useDispatch } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { resetPassword } from "../../models/actions/authActions";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import "../../assets/css/common.css";
import {validEmailRegex, validNameRegex, validPwdRegex} from "../../utils/AuthValidation";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (!validEmailRegex.test(values.email)) {
    errors.email = 'Please enter a valid email address.';
  }

  if (!values.passcode) {
    errors.passcode = 'Required';
  } else if (values.passcode.length !== 6 || isNaN(values.passcode)) {
    errors.passcode =  'Passcode must be 6 digits';
  }

  if (!values.password) {
    errors.password = 'Required';
  } else if (!validPwdRegex.test(values.password)) {
    errors.password = 'It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Required';
  } else if (!validPwdRegex.test(values.confirmPassword)) {
    errors.confirmPassword = 'It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.';
  } else if (!errors.password && (values.password !== values.confirmPassword)) {
    errors.confirmPassword = 'Your password and confirmation password do not match.';
  }

  return errors;
};

function ResetPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  return (
    <Wrapper>
      <Helmet title="Reset Password" />
      <div>
        <div align="center">
          <img src="/static/img/logo/ecosense-logo.jpg"></img>
        </div>
        <div className='s20'></div>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset your password
        </Typography>
        <div className='s20'></div>
        <Typography component="h2" variant="body1" align="center">
          Enter your email and we will send you a link to reset your password
        </Typography>
      </div>
      <Formik
        initialValues={{
          email: location.state.email,
          submit: false,
        }}
        validate={validate}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await dispatch(
              resetPassword(values.email.trim().toLowerCase(), values.passcode, values.password)
            );
            history.push("/auth/reset-pwd-confirm");
          } catch (error) {
            let message = error.message || "Something went wrong";
            if (error.code === 'InvalidParameterException') {
              message = 'Trailing spaces are not allowed in your password \n or one of parameters is invalid';
            }

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <>
                <div className="s10"></div>
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              </>
            )}
            <div className="s10"></div>
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              disabled
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="passcode"
              label="Verification code"
              value={values.passcode}
              error={Boolean(touched.passcode && errors.passcode)}
              fullWidth
              helperText={touched.passcode && errors.passcode}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="password"
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <div className="s30"></div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              classes={{ root: 'button', label: 'button-label' }}
            >
              RESET YOUR PASSWORD
            </Button>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default ResetPassword;
