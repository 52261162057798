import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { signUp, registerSubscription } from "../../models/actions/authActions";
import {
  Button, CardContent, Grid,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import "../../assets/css/common.css";
import {validEmailRegex, validPwdRegex, validNameRegex} from "../../utils/AuthValidation";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function SignUp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!validEmailRegex.test(values.email)) {
      errors.email = 'Please enter a valid email address.';
    }

    if (!values.name) {
      errors.name = 'Required';
    } else if (!validNameRegex.test(values.name)) {
      errors.name = 'It must not contain numbers or special characters.';
    }

    if (!values.password) {
      errors.password = 'Required';
    } else if (!validPwdRegex.test(values.password)) {
      errors.password = 'It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.';
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Required';
    } else if (!validPwdRegex.test(values.confirmPassword)) {
      errors.confirmPassword = 'It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.';
    } else if (!errors.password && (values.password !== values.confirmPassword)) {
      errors.confirmPassword = 'Your password and confirmation password do not match.';
    }

    return errors;
  };

  return (
    <Wrapper>
      <Helmet title="Sign Up" />

      <div>
        <div align="center">
          <img src="/static/img/logo/ecosense-logo.jpg"></img>
        </div>
        <div className='s20'></div>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Create your account
        </Typography>
        <div className='s20'></div>
      </div>

      <Formik
        initialValues={{
          email: "",
          name: "",
          password: "",
          confirmPassword: "",
          companyName: "",
          phoneNumber: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          submit: false,
        }}
        validate={validate}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const trimmedEmail = values.email.trim().toLowerCase();
          try {
            await dispatch(
              signUp({
                email: trimmedEmail,
                name: values.name,
                password: values.password,
              })
            );

            //Register Subscription Information for Mitigator
            // await dispatch(
            //   registerSubscription({
            //     email: trimmedEmail,
            //     name: values.name,
            //     companyName: values.companyName,
            //     phoneNumber: values.phoneNumber,
            //     address: values.address,
            //     city: values.city,
            //     state: values.state,
            //     zipCode: values.zipCode
            //   })
            // );

            history.push({
              pathname: '/auth/verify-code',
              state: { email: trimmedEmail }
            });
          } catch (error) {
            let message = error.message || "Something went wrong";
            if (error.code === 'UserNotConfirmedException') {
              message = 'Account not verified yet';
            } else if (error.code === 'PasswordResetRequiredException') {
              message = 'Existing user found. Please reset your password';
            } else if (error.code === 'NotAuthorizedException') {
              message = 'Forgot Password?';
            } else if (error.code === 'UserNotFoundException') {
              message = 'User does not exist!';
            } else if (error.code === 'UsernameExistsException') {
              message = 'User already exists!';
            } else if(error.code === 'InvalidParameterException') {
              message = 'Trailing spaces are not allowed in your password \n or one of parameters is invalid';
            }

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <div class="s20"></div>
            <Typography variant="h6" gutterBottom>
              Account information
            </Typography>
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <TextField
              type="text"
              name="name"
              label="Name"
              value={values.name}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <TextField
              type="password"
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <div className="s30"></div>
            <Typography variant="h6" gutterBottom>
              Business information
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <TextField
                  name="companyName"
                  label="Company name"
                  value={values.companyName}
                  fullWidth
                  error={Boolean(touched.companyName && errors.companyName)}
                  fullWidth
                  helperText={touched.companyName && errors.companyName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="phoneNumber"
                  label="Phone number"
                  value={values.phoneNumber}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  fullWidth
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              </Grid>
            </Grid>
            <TextField
              name="address"
              label="Address"
              value={values.address}
              error={Boolean(touched.address && errors.address)}
              fullWidth
              helperText={touched.address && errors.address}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <Grid container spacing={3}>
              <Grid item md={6}>
                <TextField
                  name="city"
                  label="City"
                  value={values.city}
                  error={Boolean(touched.city && errors.city)}
                  fullWidth
                  helperText={touched.city && errors.city}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  name="state"
                  label="State"
                  value={values.state}
                  error={Boolean(touched.state && errors.state)}
                  fullWidth
                  helperText={touched.state && errors.state}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  name="zipCode"
                  label="Zipcode"
                  value={values.zipCode}
                  error={Boolean(touched.zipCode && errors.zipCode)}
                  fullWidth
                  helperText={touched.zipCode && errors.zipCode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              </Grid>
            </Grid>
            <div class="s30"></div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              classes={{ root: 'button', label: 'button-label' }}
            >
              Sign up
            </Button>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default SignUp;
