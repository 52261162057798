import React from "react";
import { useDispatch } from "react-redux";
import {Link, useHistory, useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { resendSignUp } from "../../models/actions/authActions";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import "../../assets/css/common.css";
import {validEmailRegex, validPwdRegex} from "../../utils/AuthValidation";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function PasscodeRequired() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  return (
    <Wrapper>
      <Helmet title="Reset Password" />
      <div align="center">
        <img src="/static/img/logo/ecosense-logo.jpg"></img>
      </div>
      <div className='s20'></div>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Email Verification Required
      </Typography>
      <div className='s20'></div>
      <Typography component="h2" variant="body1" align="center">
        You have not verified your email address(<b>{location.state.email}</b>) yet. Please check your email inbox for an email from Ecosense and follow the instructions provided
      </Typography>
      <Typography component="h2" variant="body1" align="center">

      </Typography>

      <Formik
        initialValues={{
          email: location.state.email,
          submit: false,
        }}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const trimmedEmail = location.state.email.trim().toLowerCase();
            await dispatch(
              resendSignUp(trimmedEmail)
            );
            history.push({
              pathname: '/auth/verify-code',
              state: { email: trimmedEmail }
            });
          } catch (error) {
            let message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="s30"></div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              classes={{ root: 'button', label: 'button-label' }}
            >
              Resend Verification Email
            </Button>
            <Button
              component={Link}
              to={location.state.from}
              fullWidth
              color="primary"
              classes={{ label: 'link-text' }}
            >
              Go back
            </Button>
            <div className="auth-padding"></div>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default PasscodeRequired;
