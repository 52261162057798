/* eslint-disable import/first */
import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  Monitor,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
} from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from "../components/AuthGuard";

// Auth components
import SignIn from "../views/auth/SignIn";
import VerifyPasscode from "../views/auth/VerifyPasscode";
import SignUp from "../views/auth/SignUp";
import ForgotPassword from "../views/auth/ForgotPassword";
import ResetPassword from "../views/auth/ResetPassword";
import PasscodeRequired from "../views/auth/PasscodeRequired";
import ResetPwdConfirm from "../views/auth/ResetPwdConfirm";
import Page404 from "../views/auth/Page404";
import Page500 from "../views/auth/Page500";

// Components components
import Accordion from "../views/components/Accordion";
import Alerts from "../views/components/Alerts";
import Avatars from "../views/components/Avatars";
import Badges from "../views/components/Badges";
import Buttons from "../views/components/Buttons";
import Cards from "../views/components/Cards";
import Chips from "../views/components/Chips";
import Dialogs from "../views/components/Dialogs";
import Lists from "../views/components/Lists";
import Menus from "../views/components/Menus";
import Pagination from "../views/components/Pagination";
import Progress from "../views/components/Progress";
import Snackbars from "../views/components/Snackbars";
import Tooltips from "../views/components/Tooltips";

// Dashboards components
const Default = async(() => import("../views/dashboards/Default"));
const Analytics = async(() => import("../views/dashboards/Analytics"));
const SaaS = async(() => import("../views/dashboards/SaaS"));

// Forms components
import SelectionCtrls from "../views/forms/SelectionControls";
import Selects from "../views/forms/Selects";
import TextFields from "../views/forms/TextFields";
const Pickers = async(() => import("../views/forms/Pickers"));
const Dropzone = async(() => import("../views/forms/Dropzone"));
const Editors = async(() => import("../views/forms/Editors"));
const Formik = async(() => import("../views/forms/Formik"));

// Icons components
import MaterialIcons from "../views/icons/MaterialIcons";
const FeatherIcons = async(() => import("../views/icons/FeatherIcons"));

// Pages components
import Blank from "../views/pages/Blank";
import OrderList from "../views/invoices/OrderList";
import OrderDetails from "../views/invoices/OrderDetails";
import SubscriptionList from "../views/invoices/SubscriptionList";
import SubscriptionDetails from "../views/invoices/SubscriptionDetails";
import InvoiceSampleDetails from "../views/pages/InvoiceSampleDetails";
import InvoiceSampleList from "../views/pages/InvoiceSampleList";
import OrderSampleList from "../views/pages/OrderSampleList";
import Pricing from "../views/pages/Pricing";
import Settings from "../views/pages/Settings";
import Projects from "../views/pages/Projects";
import Chat from "../views/pages/Chat";
const Profile = async(() => import("../views/pages/Profile"));
const Tasks = async(() => import("../views/pages/Tasks"));
const Calendar = async(() => import("../views/pages/Calendar"));

// Tables components
import SimpleTable from "../views/tables/SimpleTable";
import AdvancedTable from "../views/tables/AdvancedTable";

// Chart components
const Chartjs = async(() => import("../views/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../views/maps/GoogleMaps"));
const VectorMaps = async(() => import("../views/maps/VectorMaps"));

// Documentation
import Welcome from "../views/docs/Welcome";
import GettingStarted from "../views/docs/GettingStarted";
import EnvironmentVariables from "../views/docs/EnvironmentVariables";
import Deployment from "../views/docs/Deployment";
import Theming from "../views/docs/Theming";
import StateManagement from "../views/docs/StateManagement";
import APICalls from "../views/docs/APICalls";
import ESLintAndPrettier from "../views/docs/ESLintAndPrettier";
import Support from "../views/docs/Support";
import Changelog from "../views/docs/Changelog";

// Landing
import Landing from "../views/presentation/Landing";

// Protected routes
import ProtectedPage from "../views/protected/ProtectedPage";

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/",
  header: "Reference",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/",
      name: "Default",
      component: Default,
    },
    {
      path: "/analytics",
      name: "Analytics",
      component: Analytics,
    },
    {
      path: "/saas",
      name: "SaaS",
      component: SaaS,
    },
  ],
  component: null,
};

const pagesRoutes = {
  id: "Pages",
  path: "/pages",
  icon: <Layout />,
  children: [
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile,
    },
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settings,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/pages/chat",
      name: "Chat",
      component: Chat,
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank,
    },
  ],
  component: null,
};

const projectsRoutes = {
  id: "Projects",
  path: "/projects",
  icon: <Briefcase />,
  badge: "8",
  component: Projects,
  children: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const invoiceRoutes = {
  id: "Invoices",
  path: "/invoices",
  icon: <CreditCard />,
  children: [
    {
      path: "/invoices/orders",
      name: "Orders",
      component: OrderList,
    },
    {
      path: "/invoices/orders/detail",
      name: "Order Details",
      component: OrderDetails,
    },
    {
      path: "/invoices/subscriptions",
      name: "Subscriptions",
      component: SubscriptionList,
    },
    {
      path: "/invoices/subscriptions/detail",
      name: "Subscriptions Details",
      component: SubscriptionDetails,
    },
  ],
  component: null,
};

// Sample
const invoiceSampleRoutes = {
  id: "Invoices (sample)",
  path: "/invoicesSample",
  icon: <CreditCard />,
  children: [
    {
      path: "/invoicesSample",
      name: "List",
      component: InvoiceSampleList,
    },
    {
      path: "/invoicesSample/detail",
      name: "Details",
      component: InvoiceSampleDetails,
    },
  ],
  component: null,
};

// Sample
const orderSampleRoutes = {
  id: "Order (sample)",
  path: "/orderSampleList",
  icon: <ShoppingCart />,
  component: OrderSampleList,
  children: null,
};

const tasksRoutes = {
  id: "Tasks",
  path: "/tasks",
  icon: <CheckSquare />,
  badge: "17",
  component: Tasks,
  children: null,
};

const calendarRoutes = {
  id: "Calendar",
  path: "/calendar",
  icon: <CalendarIcon />,
  component: Calendar,
  children: null,
};

const componentsRoutes = {
  id: "Components",
  path: "/components",
  header: "Elements",
  icon: <Grid />,
  children: [
    {
      path: "/components/alerts",
      name: "Alerts",
      component: Alerts,
    },
    {
      path: "/components/accordion",
      name: "Accordion",
      component: Accordion,
    },
    {
      path: "/components/avatars",
      name: "Avatars",
      component: Avatars,
    },
    {
      path: "/components/badges",
      name: "Badges",
      component: Badges,
    },
    {
      path: "/components/buttons",
      name: "Buttons",
      component: Buttons,
    },
    {
      path: "/components/cards",
      name: "Cards",
      component: Cards,
    },
    {
      path: "/components/chips",
      name: "Chips",
      component: Chips,
    },
    {
      path: "/components/dialogs",
      name: "Dialogs",
      component: Dialogs,
    },
    {
      path: "/components/lists",
      name: "Lists",
      component: Lists,
    },
    {
      path: "/components/menus",
      name: "Menus",
      component: Menus,
    },
    {
      path: "/components/pagination",
      name: "Pagination",
      component: Pagination,
    },
    {
      path: "/components/progress",
      name: "Progress",
      component: Progress,
    },
    {
      path: "/components/snackbars",
      name: "Snackbars",
      component: Snackbars,
    },
    {
      path: "/components/tooltips",
      name: "Tooltips",
      component: Tooltips,
    },
  ],
  component: null,
};

const formsRoutes = {
  id: "Forms",
  path: "/forms",
  icon: <CheckSquare />,
  children: [
    {
      path: "/forms/pickers",
      name: "Pickers",
      component: Pickers,
    },
    {
      path: "/forms/selection-controls",
      name: "Selection Controls",
      component: SelectionCtrls,
    },
    {
      path: "/forms/selects",
      name: "Selects",
      component: Selects,
    },
    {
      path: "/forms/text-fields",
      name: "Text Fields",
      component: TextFields,
    },
    {
      path: "/forms/dropzone",
      name: "Dropzone",
      component: Dropzone,
    },
    {
      path: "/forms/editors",
      name: "Editors",
      component: Editors,
    },
    {
      path: "/forms/formik",
      name: "Formik",
      component: Formik,
    },
  ],
  component: null,
};

const tablesRoutes = {
  id: "Tables",
  path: "/tables",
  icon: <List />,
  children: [
    {
      path: "/tables/simple-table",
      name: "Simple Table",
      component: SimpleTable,
    },
    {
      path: "/tables/advanced-table",
      name: "Advanced Table",
      component: AdvancedTable,
    },
  ],
  component: null,
};

const iconsRoutes = {
  id: "Icons",
  path: "/icons",
  icon: <Heart />,
  children: [
    {
      path: "/icons/material-icons",
      name: "Material Icons",
      component: MaterialIcons,
    },
    {
      path: "/icons/feather-icons",
      name: "Feather Icons",
      component: FeatherIcons,
    },
  ],
  component: null,
};

const chartRoutes = {
  id: "Charts",
  path: "/charts",
  icon: <PieChart />,
  component: Chartjs,
  children: null,
};

const mapsRoutes = {
  id: "Maps",
  path: "/maps",
  icon: <Map />,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps,
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps,
    },
  ],
  component: null,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const documentationRoutes = {
  id: "Documentation",
  path: "/documentation",
  header: "Material App",
  icon: <BookOpen />,
  children: [
    {
      path: "/documentation/welcome",
      name: "Welcome",
      component: Welcome,
    },
    {
      path: "/documentation/getting-started",
      name: "Getting Started",
      component: GettingStarted,
    },
    {
      path: "/documentation/environment-variables",
      name: "Environment Variables",
      component: EnvironmentVariables,
    },
    {
      path: "/documentation/deployment",
      name: "Deployment",
      component: Deployment,
    },
    {
      path: "/documentation/theming",
      name: "Theming",
      component: Theming,
    },
    {
      path: "/documentation/state-management",
      name: "State Management",
      component: StateManagement,
    },
    {
      path: "/documentation/api-calls",
      name: "API Calls",
      component: APICalls,
    },
    {
      path: "/documentation/eslint-and-prettier",
      name: "ESLint & Prettier",
      component: ESLintAndPrettier,
    },
    {
      path: "/documentation/support",
      name: "Support",
      component: Support,
    },
  ],
  component: null,
};

const changelogRoutes = {
  id: "Changelog",
  path: "/changelog",
  badge: "v2.0.3",
  icon: <List />,
  component: Changelog,
  children: null,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  pagesRoutes,
  projectsRoutes,
  orderSampleRoutes,
  invoiceSampleRoutes,
  tasksRoutes,
  calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  documentationRoutes,
  changelogRoutes,
  invoiceRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  authRoutes,
  invoiceRoutes,
  documentationRoutes,
  changelogRoutes,
  dashboardsRoutes,
  pagesRoutes,
  invoiceSampleRoutes,
  orderSampleRoutes,
  projectsRoutes,
  tasksRoutes,
  calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
];
