import * as types from "../../constants";
import {
  signIn as authSignIn,
  signOut as authSignOut,
  signUp as authSignUp,
  resendSignUp as authResendSignUp,
  registerSubscription as authRegisterSubscription,
  verifyCode as authVerifyCode,
  forgotPassword as authForgotPassword,
  resetPassword as authResetPassword,
} from "../services/authService";

export function signIn(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    return authSignIn(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}

export function verifyCode(code) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_VERIFY_CODE_REQUEST });

    return authVerifyCode(code)
      .then((response) => {
        //alert(JSON.stringify(response));
        dispatch({
          type: types.AUTH_VERIFY_CODE_SUCCESS,
        })
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_VERIFY_CODE_FAILURE });
        throw error;
      });
  };
}

export function signUp(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
        })
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function resendSignUp(email) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESEND_SIGNUP_REQUEST });

    return authResendSignUp(email)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESEND_SIGNUP_SUCCESS,
        })
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESEND_SIGNUP_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_OUT_REQUEST });

    return authSignOut()
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_OUT_SUCCESS
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_OUT_FAILURE });
        throw error;
      });
  };
}


export function forgotPassword(email) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_FORGOT_PASSWORD_REQUEST });

    return authForgotPassword(email)
      .then((response) => {
        dispatch({
          type: types.AUTH_FORGOT_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_FORGOT_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function resetPassword(email, passcode, password) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(email, passcode, password)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function registerSubscription(info) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_REGISTER_SUBSCRIPTION_REQUEST });
    return authRegisterSubscription(info)
      .then((response) => {
        dispatch({
          type: types.AUTH_REGISTER_SUBSCRIPTION_SUCCESS,
        })
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_REGISTER_SUBSCRIPTION_FAILURE });
        throw error;
      });
  };
}
